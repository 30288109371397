// HowItWorksSection.js
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FaUserPlus, FaComments, FaChartPie, FaFileAlt } from 'react-icons/fa';

const HowItWorksSection = () => {
  return (
    <Container fluid style={{ padding: '4rem 2rem', textAlign: 'center' }}>
      <h2 style={{ fontSize: '2.5rem', fontWeight: 'bold', color: '#333' }}>How It Works</h2>
      <p style={{ color: '#666', fontSize: '1rem', marginTop: '0.5rem' }}>
      BrandInsights transforms brand perceptions into actionable insights with AI-powered interviews and data visualizations.
      </p>

      <Row className="mt-5" style={{ justifyContent: 'center' }}>
        {/* Step 1: Generate Participants */}
        <Col md={3} sm={6} className="mb-4">
          <div style={{
            backgroundColor: '#4a90e2',
            padding: '1.5rem',
            borderRadius: '50%',
            display: 'inline-block',
            marginBottom: '1rem'
          }}>
            <FaUserPlus size={30} color="white" />
          </div>
          <h5 style={{ fontWeight: 'bold', color: '#333' }}>Generate Participants</h5>
          <p style={{ color: '#666', fontSize: '0.9rem' }}>
            AI generates synthetic participants with specific personas to simulate real audience perspectives.
          </p>
        </Col>

        {/* Step 2: Conduct Interview */}
        <Col md={3} sm={6} className="mb-4">
          <div style={{
            backgroundColor: '#8e44ad',
            padding: '1.5rem',
            borderRadius: '50%',
            display: 'inline-block',
            marginBottom: '1rem'
          }}>
            <FaComments size={30} color="white" />
          </div>
          <h5 style={{ fontWeight: 'bold', color: '#333' }}>Conduct Interview</h5>
          <p style={{ color: '#666', fontSize: '0.9rem' }}>
            Interactive AI-driven interviews are conducted on topics like brand perception and market conditions.
          </p>
        </Col>

        {/* Step 3: Analyze Data */}
        <Col md={3} sm={6} className="mb-4">
          <div style={{
            backgroundColor: '#e74c3c',
            padding: '1.5rem',
            borderRadius: '50%',
            display: 'inline-block',
            marginBottom: '1rem'
          }}>
            <FaChartPie size={30} color="white" />
          </div>
          <h5 style={{ fontWeight: 'bold', color: '#333' }}>Analyze Data</h5>
          <p style={{ color: '#666', fontSize: '0.9rem' }}>
            Qualitative responses are quantified into measurable metrics to evaluate brand health.
          </p>
        </Col>

        {/* Step 4: Display Insights */}
        <Col md={3} sm={6} className="mb-4">
          <div style={{
            backgroundColor: '#27ae60',
            padding: '1.5rem',
            borderRadius: '50%',
            display: 'inline-block',
            marginBottom: '1rem'
          }}>
            <FaFileAlt size={30} color="white" />
          </div>
          <h5 style={{ fontWeight: 'bold', color: '#333' }}>Display Insights</h5>
          <p style={{ color: '#666', fontSize: '0.9rem' }}>
            Results are visualized in charts and graphs, and reports are generated to support data-driven decisions.
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default HowItWorksSection;
