import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';

function NotFoundPage() {
  return (
    <Container
      fluid
      className="d-flex align-items-center justify-content-center vh-100"
      style={{
      }}
    >
      <Row className="text-center">
        <Col>
          <h1 style={{ fontSize: '8rem', fontWeight: 'bold', color: '#333' }}>
            4<span style={{ color: '#ff6f61' }}>0</span>4
          </h1>
          <p style={{ fontSize: '1.5rem', color: '#666' }}>
            There’s nothing here!
          </p>
          <p style={{ color: '#999' }}>
            Not all who wander are lost. But you certainly are.
          </p>
          <Button variant="light" href="/" style={{ fontSize: '1rem', padding: '0.75rem 1.5rem', color: 'white', borderColor: '#ddd', background:"blueviolet" }}>
            Go to Homepage
          </Button>
        </Col>
      </Row>
    </Container>
  );
}

export default NotFoundPage;
