// HowItWorksSection.js
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const WorkProcess = () => {
  return (
    <Container fluid style={{ padding: '5rem 2rem', textAlign: 'center', color: '#333' }}>
      <h2 style={{ fontSize: '3rem', fontWeight: '700' }}>Why BrandInsights?</h2>
      <p style={{ color: '#555', fontSize: '1.1rem', margin: '1rem auto', maxWidth: '80vw' }}>
        A cost-effective, comprehensive, and scalable approach to conducting secondary research. Bring in your own participants for an all-human, all-AI, or mixed research setup.
      </p>

      <Row className="justify-content-center" style={{ marginTop: '2rem' }}>
        <Col>
          <div style={{
            fontSize: '1.05rem',
            textAlign: 'center',
            color: '#444',
            lineHeight: '1.6',
            padding: '1rem',
            backgroundColor: '#ffffff',
            borderRadius: '8px',
            transition: 'all 0.3s ease'
          }}>
            <p>
              Large Language Models (LLMs) provide unique insights into brand perception by analyzing consumer discussions and searches online.With our tool, LLMs aggregate and interpret vast text data from social media, reviews, and forums to detect patterns in consumer sentiment and keyword trends. This reveals how audiences associate specific qualities, emotions, or values with a brand, potentially differing from the intended brand identity.
         
              As search behavior evolves, many people now seek conversational responses that address their unique needs. Advanced LLMs interpret not only brand mentions but also contextual clues indirectly linked to brand identity. This helps brands understand how audiences search for solutions, enabling them to identify themes or issues that resonate with potential customers and refine their messaging accordingly.
            </p>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default WorkProcess;
