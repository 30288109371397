import React, { useRef } from 'react';
import { Form, Button, Container, Card, Row, Col } from 'react-bootstrap';
import emailjs from '@emailjs/browser';
import Header from "../components/Header"

const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        process.env.REACT_APP_SERVICE_ID,      // Replace with your actual service ID
        process.env.REACT_APP_TEMPLATE_ID,      // Replace with your actual template ID
        form.current,
        process.env.REACT_APP_PUBLIC_KEY       // Replace with your actual public key
      )
      .then(
        (result) => {
          console.log('SUCCESS!', result.text);
          alert('Message sent successfully!');
        },
        (error) => {
          console.log('FAILED...', error.text);
          alert('Failed to send message. Please try again.');
        }
      );
  };

  return (
    <>
    <Header />
    <Container className="d-flex justify-content-center align-items-center min-vh-100">
      <Row className="w-100">
        <Col md={8} lg={6} className="mx-auto">
          <Card className="p-4">
            <Card.Body>
              <h2 className="text-center mb-4">Contact Us</h2>
              <Form ref={form} onSubmit={sendEmail}>
                <Form.Group controlId="user_name" className="mb-3">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="user_name"
                    placeholder="Enter your name"
                    required
                  />
                </Form.Group>

                <Form.Group controlId="user_email" className="mb-3">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    name="user_email"
                    placeholder="Enter your email"
                    required
                  />
                </Form.Group>

                <Form.Group controlId="message" className="mb-3">
                  <Form.Label>Message</Form.Label>
                  <Form.Control
                    as="textarea"
                    name="message"
                    rows={4}
                    placeholder="Write your message here"
                    required
                  />
                </Form.Group>

                <Button variant="primary" type="submit" className="w-100">
                  Send
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
    </>
  );
};

export default Contact;
