import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import '../styles/dashboard.css';

const RenderReportContent = ({ selectedBrands, brandsData }) => {
  const [scrollProgress, setScrollProgress] = useState(0);

  const handleScroll = () => {
    const scrollTop = document.documentElement.scrollTop;
    const scrollHeight = document.documentElement.scrollHeight - document.documentElement.clientHeight;
    const scrollPercent = (scrollTop / scrollHeight) * 100;
    setScrollProgress(scrollPercent);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <Container fluid className="report-container p-5">
      {/* Scroll Progress Bar */}
      <div className="scroll-progress" style={{ height: `${scrollProgress}%` }} />

      <Row className="justify-content-center mb-5">
        <Col lg={10} className="text-center">
          {selectedBrands.map((brand) => (
            <div key={brand}>
              <h2 className="report-title mb-4">{brand} Health Report</h2>
              <div className="report-content">
                {brandsData[brand]?.report.map((item, index) => (
                  <div key={index} className="report-item mb-4 p-4">
                    <h3 className="report-item-title">{item.title}</h3>
                    <p className="report-item-description">{item.description}</p>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </Col>
      </Row>
    </Container>
  );
};

export default RenderReportContent;
