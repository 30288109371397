// Footer.js
import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const Footer = () => {
  const navigate = useNavigate();

  return (
    <Container
      fluid
      style={{
        backgroundColor: '#050d30',
        color: 'white',
        padding: '4rem 2rem',
      }}
    >
      <Row>
        {/* Column 1: About Section */}
        <Col>
          <p style={{ fontSize: '1.3rem', color: '#cccccc', marginTop: '1rem',textAlign:"center" }}>
           We help you to evaluate your brand and give you the overall idea to grow your business. Connect with us for more.
          </p>
          <Button
              variant="primary"
              style={{
                backgroundColor: '#4a90e2',
                borderColor: '#4a90e2',
                padding: '0.5rem 1.5rem',
                margin:"auto",
                marginTop:"10px",
                display:"block"
              }}
              onClick={() => navigate("/contact")}
            >
              Contact us
            </Button>
        </Col>

        {/* <Col md={4} sm={6} className="mb-4">
          <h5 style={{ fontWeight: 'bold', color: '#ffffff' }}>Quick Links</h5>
          <ul style={{ listStyleType: 'none', padding: 0, marginTop: '1rem' }}>
            <li style={{ marginBottom: '0.5rem' }}><a href="/" style={{ color: '#4a90e2', textDecoration: 'none' }}>Home</a></li>
            <li style={{ marginBottom: '0.5rem' }}><a href="/" style={{ color: '#4a90e2', textDecoration: 'none' }}>Blog</a></li>
            <li style={{ marginBottom: '0.5rem' }}><a href="/" style={{ color: '#4a90e2', textDecoration: 'none' }}>Contact Us</a></li>
          </ul>
        </Col> */}

        {/* Column 3: Subscribe and Social Icons */}
        {/* <Col md={4} sm={6}>
          <h5 style={{ fontWeight: 'bold', color: '#ffffff' }}>Stay Connected</h5>
          <p style={{ fontSize: '0.9rem', color: '#cccccc', marginTop: '1rem' }}>
            Subscribe to our newsletter for the latest updates and resources.
          </p>
          <Form className="d-flex" style={{ marginTop: '1rem' }}>
            <Form.Control
              type="email"
              placeholder="Your email"
              style={{
                borderRadius: '20px 0 0 20px',
                borderColor: '#4a90e2',
                padding: '0.5rem',
              }}
            />
            <Button
              variant="primary"
              style={{
                backgroundColor: '#4a90e2',
                borderColor: '#4a90e2',
                borderRadius: '0 20px 20px 0',
                padding: '0.5rem 1.5rem',
              }}
            >
              Subscribe
            </Button>
          </Form>
          <div style={{ marginTop: '1.5rem' }}>
            <FaFacebook size={20} color="#4a90e2" style={{ marginRight: '10px' }} />
            <FaInstagram size={20} color="#4a90e2" style={{ marginRight: '10px' }} />
            <FaLinkedin size={20} color="#4a90e2" style={{ marginRight: '10px' }} />
            <FaEnvelope size={20} color="#4a90e2" />
          </div>
        </Col> */}
      </Row>

      <hr style={{ borderColor: '#333333', margin: '2rem 0' }} />

      <Row>
        <Col className="text-center">
          <p style={{ fontSize: '0.8rem', color: '#666666' }}>
            © 2024 BrandInsights.org
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default Footer;
