import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Dashboard from './pages/dashboard';
import LandingPage from "./pages/LandingPage"
import NotFound from './pages/NotFound';
import Contact from './pages/Contact';
import 'bootstrap/dist/css/bootstrap.min.css';
import About from './pages/About';


function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/dashboard" element={<Dashboard />} />
<Route path="/contact" element={<Contact />} />
<Route path="/about" element={<About />} />


          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
