// Header.js
import React from 'react';
import { Navbar, Nav, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const Header = () => {
  const navigate = useNavigate();

  return (
    <Navbar
      expand="lg"
      style={{
        padding: '1rem 2rem',
        backgroundColor: 'transparent',
      }}
    >
      <Navbar.Brand href="/">
        <img
          src='/logo.svg'
          alt="BrandInsights.org Logo"
          style={{ width: 'auto', height: '55px' }}
        />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ms-auto">
          <Nav.Link   onClick={() => navigate("/about")} style={{ color: '#333', fontWeight: '500', marginRight: '1rem' }}>About Us</Nav.Link>
          <Nav.Link   onClick={() => navigate("/contact")} style={{ color: '#333', fontWeight: '500', marginRight: '1rem' }}>Contact Us</Nav.Link>
          <Button
              variant="primary"
              style={{
                backgroundColor: '#4a90e2',
                borderColor: '#4a90e2',
                color: 'white',
                padding: '0.7rem 1.5rem',
                borderRadius: '25px',
                marginRight: '1rem',
              }}
              onClick={() => navigate("/dashboard")}
            >
              Dashboard
            </Button>
       
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Header;
