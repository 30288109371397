import React from 'react';
import Plot from 'react-plotly.js';

const TreeMapChart = ({ jsonData }) => {
  console.log("Data received for the treemap:", jsonData);
  if (!jsonData) return <p>No data available.</p>;

  // Prepare data for the treemap
  const labels = [];
  const parents = [];
  const values = [];
  const text = []; // New array to hold descriptions

  // Recursive function to traverse the data structure
  const traverseData = (data, parentName) => {
    data.forEach(item => {
      labels.push(item.name);
      values.push(item.value);
      parents.push(parentName);
      text.push(item.description || ``); 

      // If the item has children, traverse them as well
      if (item.children) {
        traverseData(item.children, item.name);
      }
    });
  };

  // Start traversing from the root level
  traverseData(jsonData.children, 'Search Intents'); 

  const trace = {
    type: 'treemap',
    labels,
    parents,
    values,
    text, // Pass the descriptions to Plotly
    textinfo: 'label+value+text', // Include text in the treemap display
    textfont: {
      size: 18,
    },
    marker: {
      line: {
        width: 2,
      },
    },
  };

  const data = [trace];

  const layout = {
    height: 800,
    width: 1100,
  };

  return (
    <Plot
      data={data}
      layout={layout}
    />
  );
};

export default TreeMapChart;
