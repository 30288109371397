import React from 'react';
import Plot from 'react-plotly.js';

const BoxPlotComponent = ({ scores, color }) => {
  const scoreData = scores;

  return (
    <div>
      <Plot
        data={[
          {
            y: scoreData,
            type: 'box',
            name: 'Promotion Score',
            boxpoints: 'all',
            jitter: 0.3,
            pointpos: -1.8,
            marker: { color: color || 'rgba(0, 123, 255, 0.7)' },
          },
        ]}
        layout={{
          yaxis: {
            title: 'Score',
          },
          showlegend: false,
          boxmode: 'group',  // Ensures boxes don’t overlap if multiple
          width: 400,        // Adjusts the component's total width
          margin: { l: 40, r: 20, t: 20, b: 40 },  // Adjusts spacing
        }}
        config={{
          displayModeBar: false,  // Hides the toolbar for a cleaner look
        }}
      />
    </div>
  );
};

export default BoxPlotComponent;
