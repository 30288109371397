import Header from '../components/Header';
// About.jsx
import React from 'react';
import { Row, Col, Image, Container } from 'react-bootstrap';
import { FaRegLightbulb, FaChartBar, FaCogs } from 'react-icons/fa';

const About = () => {

  return (
    <>
    <Header />
    <Container fluid style={{ padding: '40px', backgroundColor: '#f0f4f8' }}>
      {/* Header */}
      <Row className="justify-content-center text-center mb-5">
        <Col lg={10} md={12}>
          <h1 style={{ fontSize: '3rem', fontWeight: 'bold', marginBottom: '20px', color: '#1d2d50' }}>
            Welcome to BrandInsights.org
          </h1>
          <p style={{ fontSize: '1.2rem', lineHeight: '1.8', color: '#4a4e69', margin: '0 auto', maxWidth: '800px' }}>
            At BrandInsights, we empower brands with a deeper understanding of their market stance. Leveraging innovative AI and advanced data analytics, we turn complex audience feedback into actionable strategies that help brands adapt and thrive.
          </p>
        </Col>
      </Row>

      {/* Section 1: Our Mission */}
      <Row className="align-items-center mb-5" style={{ backgroundColor: '#fff', borderRadius: '8px', padding: '40px' }}>
        <Col lg={6} md={12} className="mb-4 mb-lg-0">
          <div className="d-flex align-items-center mb-3">
            <FaRegLightbulb size={40} color="#1d2d50" style={{ marginRight: '15px' }} />
            <h2 style={{ fontSize: '2.5rem', fontWeight: '600', color: '#1d2d50' }}>Our Mission</h2>
          </div>
          <p style={{ fontSize: '1.1rem', lineHeight: '1.8', color: '#4a4e69', textAlign: "justify" }}>
            Our mission is to provide brands with a crystal-clear view of how they are perceived in an ever-evolving market. We combine AI-driven insights with human understanding to create a unique lens into consumer sentiment, guiding brands to make smarter, more confident decisions.
          </p>
        </Col>
        <Col lg={6} md={12}>
          <Image src="https://img.freepik.com/free-vector/businessman-looking-camera-target-it-s-like-business-looking-way-be-successful-vector-business-illustration-concept_1150-60931.jpg" rounded fluid />
        </Col>
      </Row>

      {/* Section 2: What We Offer */}
      <Row className="align-items-center mb-5" style={{ backgroundColor: '#fff', borderRadius: '8px', padding: '40px' }}>
        <Col lg={6} md={12} className="order-lg-2 mb-4 mb-lg-0">
          <div className="d-flex align-items-center mb-3">
            <FaChartBar size={40} color="#1d2d50" style={{ marginRight: '15px' }} />
            <h2 style={{ fontSize: '2.5rem', fontWeight: '600', color: '#1d2d50' }}>What We Offer</h2>
          </div>
          <p style={{ fontSize: '1.1rem', lineHeight: '1.8', color: '#4a4e69', textAlign: "justify" }}>
            From in-depth audience insights to trend analysis, BrandInsights offers a range of tools that keep you connected with your consumers’ evolving needs and expectations. Our platform translates complex data into clear metrics that highlight brand strengths, growth areas, and opportunities to deepen brand loyalty.
          </p>
        </Col>
        <Col lg={6} md={12} className="order-lg-1">
          <Image src="https://media.istockphoto.com/id/1250438896/vector/businesswoman-climbs-on-a-stack-of-books-to-knowledge-education-and-professional-career.jpg?s=612x612&w=0&k=20&c=RhzXsxRd7ezTr7nrDXV5PZVaqmm0CK7NxLPfyW_7uZs=" rounded fluid />
        </Col>
      </Row>

      {/* Section 3: How We Do It */}
      <Row className="align-items-center mb-5" style={{ backgroundColor: '#fff', borderRadius: '8px', padding: '40px' }}>
        <Col lg={6} md={12} className="mb-4 mb-lg-0">
          <div className="d-flex align-items-center mb-3">
            <FaCogs size={40} color="#1d2d50" style={{ marginRight: '15px' }} />
            <h2 style={{ fontSize: '2.5rem', fontWeight: '600', color: '#1d2d50' }}>How We Do It</h2>
          </div>
          <p style={{ fontSize: '1.1rem', lineHeight: '1.8', color: '#4a4e69', textAlign: "justify" }}>
            Utilizing state-of-the-art AI techniques, our platform combines natural language processing, machine learning, and big data analysis to interpret qualitative feedback and market sentiment. This approach enables us to deliver real-time, adaptive insights, allowing your brand to respond to shifts in consumer behavior with precision and agility.
          </p>
        </Col>
        <Col lg={6} md={12}>
          <Image src="https://digifloat.io/wp-content/uploads/2020/01/Get-Free-Illustrations.png" rounded fluid />
        </Col>
      </Row>
    </Container>
    </>
  );
};

export default About;
