// HeroSection.js
import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';


const HeroSection = () => {
  const navigate = useNavigate();

  return (
    <Container fluid style={{ padding: '3rem 2rem' }}>
      <Row className="align-items-center">
        {/* Left Container */}
        <Col lg={6} sm={12} style={{ paddingRight: '2rem' }}>
          <h1 style={{ fontSize: '3rem', fontWeight: 'bold', color: '#333' }}>
            AI-Driven <span style={{ color: '#4a90e2' }}>Brand Insights</span> Platform
          </h1>
          <p style={{ fontSize: '1.1rem', color: '#666', marginTop: '1rem' }}>
          BrandInsights empowers brands by transforming qualitative interviews into quantifiable metrics. 
            Discover insights through AI-driven interviews and powerful data visualizations.
          </p>
          <div style={{ marginTop: '1.5rem' }}>
            <Button
              variant="primary"
              style={{
                backgroundColor: '#4a90e2',
                borderColor: '#4a90e2',
                color: 'white',
                padding: '0.7rem 1.5rem',
                borderRadius: '25px',
                marginRight: '1rem',
              }}
              onClick={() => navigate("/dashboard")}
            >
              Discover Insights
            </Button>
            <Button
              variant="outline-secondary"
              style={{
                color: '#333',
                borderColor: '#ccc',
                padding: '0.7rem 1.5rem',
                borderRadius: '25px',
              }}

              onClick={() => navigate("/about")}
            >
              Learn More
            </Button>
          </div>
        </Col>

        {/* Right Container with Image */}
        <Col lg={6} sm={12}>
          <Row className="g-3">
            <Col>
              <img
                src="https://vodusdev.blob.core.windows.net/article-banner/43/7d04e337-bd29-4db3-b25f-7f236ba32668.png"
                alt="Brand insights dashboard"
                style={{ width: '100%', height: 'auto', borderRadius: '8px' }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default HeroSection;
